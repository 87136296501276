import React, { useEffect, useState } from 'react';
import edulogo from "../../img/pick/edu_advisor_logo.png";
import done_icon from "../../img/pick/done_icon.svg";
import aspirations_edu_advisor_logo from "../../img/logo/aspirations_edu_advisor_logo.png";
import desktop_background_thumbnail from "../../img/virtualtour/desktop_background_thumbnail.jpg";
import mobile_background_thumbnail from "../../img/virtualtour/mobile_background_thumbnail.jpg";


import ReactDOMServer from 'react-dom/server';
import genaratevideo from '../../videos/chat/genarating_animation_loop.mp4'
import desktop_bg from '../../videos/personality/desktop_bg.mp4'
import mobile_bg from '../../videos/personality/mobile_bg.mp4'

import axios from 'axios';
import { useShareWindow } from '../../context/ShareWindowContext';

const PersonalityTest = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const chatSession = localStorage.getItem("chatSession");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [reportData, setReportData] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  // const [answers, setAnswers] = useState({
  //   name: '',
  //   age: '',
  //   email: '',
  //   mobile: '',
  // });
  const [completedSteps, setCompletedSteps] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [buttonText, setButtonText] = useState(false);
  const [showBlink, setShowBlink] = useState(true);
  const {
    setSelectedType,
    setShowDiv,
    showDev,
    answers, setAnswers
  } = useShareWindow();
  // Fetch sessionId from localStorage
  const session_id = chatSession || '';

  useEffect(() => {
    let blinkInterval;

    if (isSubmitting) {
      blinkInterval = setInterval(() => {
        setShowBlink((prev) => !prev);
      }, 500);
    }

    return () => clearInterval(blinkInterval);
  }, [isSubmitting]);

  // // Load answers from local storage when the component mounts
  // useEffect(() => {
  //   const savedAnswers = JSON.parse(localStorage.getItem('answers'));
  //   if (savedAnswers) {
  //     setAnswers(savedAnswers);
  //   }
  // }, []);

  // // Save answers to local storage whenever they change
  // useEffect(() => {
  //   localStorage.setItem('answers', JSON.stringify(answers));
  // }, [answers]);

  // Count completed steps based on the answers
  const countCompletedSteps = () => {
    let count = 0;
    if (answers.name) count++;
    if (answers.age) count++;
    if (answers.email) count++;
    if (answers.mobile) count++;
    setCompletedSteps(count);
  };

  // Handler for moving to the next step
  const handleNext = () => {
    const showError = (message) => {
      const errorElement = document.createElement('div');
      errorElement.className = 'error-message';
      errorElement.textContent = message;
      document.body.appendChild(errorElement);
      setTimeout(() => {
        document.body.removeChild(errorElement);
      }, 3000); // Remove the message after 3 seconds
    };

    if (currentStep === 1 && !answers.name) {
      showError("Please enter your name to proceed.");
      return;
    }
    if (currentStep === 2) {
      if (!answers.age) {
        showError("Please enter your age to proceed.");
        return;
      }

      const age = parseInt(answers.age, 10);

      if (isNaN(age) || age < 0 || age > 999) {
        showError("Please enter a valid age between 0 and 999.");
        return;
      }
    }

    if (currentStep === 3) {
      if (!answers.email) {
        showError("Please enter your email to proceed.");
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(answers.email)) {
        showError("Please enter a valid email address.");
        return;
      }
    }

    if (currentStep === 4) {
      if (!answers.mobile) {
        showError("Please enter your contact number to proceed.");
        return;
      }

      const phoneRegex = /^[+]?(\d{1,3})?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

      if (!phoneRegex.test(answers.mobile)) {
        showError("Please enter a valid contact number.");
        return;
      }
    }

    setCurrentStep(currentStep + 1);
    countCompletedSteps();
  };

  // Handler for moving to the previous step
  const handlePrevious = () => {
    console.log(currentStep - 1)
    setCurrentStep(currentStep - 1);
    countCompletedSteps(); // Ensure progress bar updates when going back
  };

  // Handler for updating text inputs
  const handleInputChange = (step, value) => {
    setAnswers(prevAnswers => {
      const newAnswers = {
        ...prevAnswers,
        [step]: value,
      };
      countCompletedSteps(); // Update progress bar on change
      return newAnswers;
    });
  };

  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  // Handler for finishing the wizard
  const handleFinish = async (e) => {
    setIsSubmitting(true);
    setButtonText(true);

    // // First, navigate to the new page
    // window.location.href = '/';

    // // Then, set the selected type after the page has loaded
    // setTimeout(() => {
    //   setSelectedType('type2');
    // }, 100);

    e.preventDefault();
    // Prepare questions and answers

    // Validate input fields
    if (!answers.name || !answers.email || !answers.mobile || !session_id) {
      setMessage('All fields are required');
      setSuccess(false);
      return;
    }

    try {
      const response = await axios.post('https://aspirations.stageyou.com/backend/api/profiles', {
        name: answers.name,
        email: answers.email,
        phone: answers.mobile,
        age: answers.age,
        session_id: session_id,
      });

      if (response.data.success) {
        console.log(showDev)
        setSuccess(true);
        setSelectedType('type2');
        setShowDiv(!showDev)

        setIsSubmitting(false);
        setButtonText(false);

      } else {
        setSuccess(false);
      }
    } catch (error) {
      console.error('Error creating profile:', error);
      setMessage('Error saving profile. Please try again later.');
      setSuccess(false);
    }
    // try {
    //         const response = await axios.post('/api/profiles', {
    //             name: answers.name,
    //             email: answers.email,
    //             phone: answers.mobile,
    //             session_id: session_id,
    //         });

    //         if (response.data.success) {
    //             setMessage(response.data.message);
    //             setSuccess(true);
    //         } else {
    //             setMessage(response.data.message);
    //             setSuccess(false);
    //         }
    //     } catch (error) {
    //         console.error('Error creating profile:', error);
    //         setMessage('Error saving profile. Please try again later.');
    //         setSuccess(false);
    //     }

    // setIsPopupVisible(true);


    // try {
    //   const response = await fetch('https://dashboard.knightingale.ai/ai/run/7ac54f14-d1b8-4529-bf25-58c77bf5435e', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(payload),
    //   });
    //   console.log('ss' + response.completion)
    //   if (response.ok) {

    //     const data = await response.json(); // Parse the JSON from the response
    //     console.log(data.completion);
    //     setReportData(data.completion);

    //     setIsSubmitted(true); // Show the submitted message

    //   } else {
    //     console.error('Error submitting the form:', response.statusText);
    //   }
    // } catch (error) {
    //   console.error('Error:', error);
    // } finally {
    //   // setIsPopupVisible(false); // Hide the popup
    // }



  };


  // Render progress bar
  const renderProgressBar = () => (
    <div className="progress-bar-container">
      <progress value={completedSteps} max="4" className="progress-bar" />
      <p className="progress-text">

      </p>
    </div>
  );


  // Render rating step with buttons



  const handleClose = () => {
    setIsSubmitted(true);
    window.location.href = '/'
  };


  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=600,width=800');

    // Ensure the document structure is properly formatted
    printWindow.document.write(`<html><head><title>Print</title>
       <style>
          body {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            margin: 0;
            padding: 20px;
          }
          .report-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
          }
          .report-left-side {
            text-align: left;
          }
          .report-right-side {
            text-align: right;
          }
          .report-logo {
            height: 50px;
          }
          .responseText {
            overflow-x: auto;
            white-space: pre-wrap;
            font-weight: normal;
            font-family: 'Roboto, sans-serif';
            font-size: 20px;
          }
          footer {
            margin-top: 20px;
            border-top: 1px solid #ddd;
            padding-top: 10px;
            text-align: center;
            font-size: 12px;
            color: #777;
          }
        </style>
      </head><body>`);
    printWindow.document.write('<div>');
    printWindow.document.write(`
      <div style="text-align: center; display: flex; align-items: center; justify-content: center;">
      </div>
    `);
    printWindow.document.write(`${renderResponseToHtml()}`);  // Ensure renderResponse() returns a valid HTML snippet
    printWindow.document.write(`
      <footer style="margin-top: 20px; border-top: 1px solid #ddd; padding-top: 10px;">
      </footer>
    `);
    printWindow.document.write('</div>');
    printWindow.document.write('</body></html>');

    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };


  const renderResponseToHtml = () => {
    return ReactDOMServer.renderToStaticMarkup(renderResponse());
  };

  const renderResponse = () => {
    if (reportData) {
      // Replace double asterisks with <strong> tags for bold text
      const formattedReportData = reportData
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')  // Replace double asterisks with <strong> tags
        .replace(/##(.*?)/g, '<strong>$1</strong>');  // Replace text between double hash with <strong> tags


      const getCurrentDate = () => {
        const today = new Date();
        return today.toLocaleDateString(); // Format as needed, e.g., "MM/DD/YYYY"
      };
      return (
        <div>
          {/* Header Section */}
          <header className='report-header'>
            <div className="report-left-side">
              <h1>Personality Test</h1>
              <p>Test Date : {getCurrentDate()} </p>
              <p>Test ID: {chatSession}</p>
            </div>
            <div className="report-right-side">
              <img src={edulogo} alt="Logo" className='report-logo' />
            </div>
          </header>

          {/* Main Content */}
          <pre
            className="responseText"
            style={{ overflowX: 'auto', whiteSpace: 'pre-wrap', fontWeight: 'normal', fontFamily: 'Roboto, sans-serif', fontSize: '20px' }}
            dangerouslySetInnerHTML={{ __html: formattedReportData }}
          ></pre>
        </div>
      );
    }
    return null;
  };


  return (
    <div className='personality-test-container'>

      <video className="background-video-personality desktop-video"
        playsInline
        autoPlay
        loop
        muted
        poster={desktop_background_thumbnail}
        src={desktop_bg}
      />

      <video className="background-video-personality mobile-video"
        playsInline
        autoPlay
        loop
        muted
        poster={mobile_background_thumbnail}
        src={mobile_bg}
      />


      <div className='personality-test'>
        <img src={aspirations_edu_advisor_logo} alt="Logo" className='aspirations_edu_advisor_logo' />
        <div className="wizard-container">
          <div className='wizard-header-wrapper'>
            <div className='wizard-header-wrapper'>
              <div className='wizard-header-wrapper'>

              </div>
              <div className='wizard-header-wrapper'>

              </div>
            </div>
          </div>
          <p className="progress-text">
            {currentStep < 5 && <><span> <span className='completed-steps'>{completedSteps}</span> of 4 </span>questions answered</>}

          </p>
          {currentStep < 5 && renderProgressBar()}
          {currentStep === 1 && (
            <div className="wizard-section">
              <div>
                <h3 className="section-title">Let's Discover Your Unique Personality!</h3>
                <p className="greeting-text">First things first, what's your name?</p>
              </div>
              <div className='personal-description'>

                <input
                  type="text"
                  placeholder="Enter your first name"
                  value={answers.name}
                  onChange={(e) => handleInputChange('name', e.target.value)}
                  className="input-field"
                />
              </div>
              <div>

              </div>

            </div>
          )}

          {currentStep === 2 && (
            <div className="wizard-section">
              <div>
                <h3 className="section-title">Let's Discover Your Unique Personality!</h3>
                <p className="greeting-text">What's your age?</p>
              </div>
              <div className='personal-description'>

                <input
                  type="text"
                  placeholder="Enter your age"
                  value={answers.age}
                  onChange={(e) => handleInputChange('age', e.target.value)}
                  className="input-field"
                />
              </div>
              <div>

              </div>
            </div>
          )}


          {currentStep === 3 && (
            <div className="wizard-section">
              <div>
                <h3 className="section-title">Let's Discover Your Unique Personality!</h3>
                <p className="greeting-text">What's your email?</p>
              </div>
              <div className='personal-description'>

                <input
                  type="text"
                  placeholder="Enter your email"
                  value={answers.email}
                  onChange={(e) => handleInputChange('email', e.target.value)}
                  className="input-field"
                />
              </div>
              <div>

              </div>
            </div>
          )}



          {currentStep === 4 && (
            <div className="wizard-section">
              <div>
                <h3 className="section-title">Let's Discover Your Unique Personality!</h3>
                <p className="greeting-text">What's your contact number?</p>
              </div>
              <div className='personal-description'>

                <input
                  type="text"
                  placeholder="Enter your contact number"
                  value={answers.mobile}
                  onChange={(e) => handleInputChange('mobile', e.target.value)}
                  className="input-field"
                />
              </div>
              <div>

              </div>
            </div>
          )}


          <div className="button-group">


            <div className='prev-next-container'>

              {currentStep > 1 && currentStep < 5 && (<button onClick={handlePrevious} className="button previous-button">Previous</button>)}
              {currentStep < 5 && (<button onClick={handleNext} className="button next-button">Next</button>)}
            </div>
            {currentStep === 5 && (
              <div className="wizard-section">
                <div className="wizard-section-container">
                  {buttonText ? (
                    <>
                      <div className="loader-container">
                        <div className="circle-loader"></div>
                      </div>
                      <h3 className="finish-section-title">Submission Completing...</h3>
                    </>
                  ) :
                    (
                      <>
                        <img className='finish-icon' src={done_icon} alt='done icon' />
                        <h3 className="finish-section-title">Submission Complete!</h3>
                      </>
                    )}
                  <p className="completion-text">Excited to share your personality test report and help you explore the best path forward!</p>

                  <button onClick={handleFinish} className="button finish-button" disabled={buttonText} >Continue</button>
                </div>
              </div>
            )}
          </div>
          {isPopupVisible && (
            <div className='overlay'>
              {isSubmitted ? (
                <div className='successMessage'>

                  <div className='successText'>Thank you! Your form has been submitted successfully.</div>
                  <div className='report-container-print'>

                    {renderResponse()}
                  </div>
                  <div className='printButtons'>
                    <button className='okButton' onClick={handleClose}>Home</button>
                    <button className='okButton' onClick={handlePrint}>Print Report</button>
                  </div>

                </div>
              ) : (
                <div className='popupContent'>
                  <div className='circle-loader'></div>
                  <div>
                    <video
                      className={`genarateVideo`}
                      style={{

                      }}
                      playsInline
                      autoPlay
                      loop
                      muted
                      src={genaratevideo}
                    />
                  </div>
                  <div className='popupTitle'>Generating Report...</div>
                  <div className='popupText'>Your Report is being Genarated. <br />Please wait...</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>

  );
};

export default PersonalityTest;






















