import React, { useEffect, useState } from "react";

import recordIcon from "../../img/mic_recording_icon.svg";
import voiceLogo from "../../img/mic_icon.png";
import msgSend from "../../img/Send_icon.png";
import "../ChatInput/ChatInput.css";
import { useShareWindow } from "../../context/ShareWindowContext.js";
import Cookies from 'js-cookie';

const ChatInput = ({
  textareaRef,
  input,
  handleInputChange,
  handleKeyDown,
  handleKeyUp,
  isSubmitting,
  disableMicButton,
  getPlaceholderText,
  suggestions,
  showDropdown,
  handleSuggestionClick,
  disableSendButton,
  handleMicButtonClick,
  dropdownRef,
  isRecording,

  imagePreview,
  setImagePreview,
  setSelectedImage,
  userHistory,

  boxSuggession,
  setCenterHome
}) => {
  const getSubmit = () => {
    const boxShadow = input === "" ? "none" : "";
    const display = input === "" ? "none" : "block";

    return { boxShadow, display };
  };
  const getVoice = () => {
    const display = input === "" ? "Block" : "none";
    return { display };
  };
  const getText = () => {
    const background = input === "" ? "" : "#fff";
    return { background };
  };


  const suggessionBorderColor = {
    borderColor: boxSuggession,
  };
  const { setImagePreviews, isStyled, setShowPopup, isFirstClick, setIsFirstClick,selectedType } = useShareWindow();
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [showTooltipMic, setShowTooltipMic] = useState(false);


  const handleMouseEnterSend = () => {
    if (window.innerWidth > 767) {

      const timeout = setTimeout(() => {
        setShowTooltip(true);
      }, 2000); // 2 seconds
      setHoverTimeout(timeout);
    }
  }

  const handleMouseLeaveSend = () => {
    clearTimeout(hoverTimeout);
    setShowTooltip(false);
  };


  const handleMouseEnterMic = () => {
    if (window.innerWidth > 767) {
      const timeout = setTimeout(() => {
        setShowTooltipMic(true);
      }, 2000); // 2 seconds
      setHoverTimeout(timeout);
    };
  }


  const handleMouseLeaveMic = () => {
    clearTimeout(hoverTimeout);
    setShowTooltipMic(false);
  };


  function formatCharacterCount(count) {
    return count.toString().padStart(4, '0');
  }
  const textCount = input.replace(/\s+/g, '').length;
  const formattedCount = formatCharacterCount(textCount);

  let maxChars;

  if (window.innerWidth > 767) {
    maxChars = 80;
  } else {
    maxChars = 20;
  }

  const handleStoreButtonClick = () => {
    // Get the index of the last entry
    const index = userHistory.length;

    // Example: Get the image preview value from state or props
    const imagePreviewValue = imagePreview; // Replace with your actual method of getting the image preview value

    // Retrieve the existing stored values from local storage
    const storedImagePreviews = JSON.parse(localStorage.getItem("storedImagePreviews")) || {};

    // Store the image preview value using the index as the ID
    storedImagePreviews[index] = imagePreviewValue;

    // Store the updated values back to local storage
    localStorage.setItem("storedImagePreviews", JSON.stringify(storedImagePreviews));

    // Optionally, you can log a message to indicate that the values are stored


  };

  const handleRetrieveButtonClick = () => {
    // Retrieve the stored image previews from local storage
    const storedImagePreviews = JSON.parse(localStorage.getItem("storedImagePreviews"));

    // Check if there are stored image previews
    if (storedImagePreviews) {
      setImagePreviews(storedImagePreviews);
      // setImagePreview(null);

    } else {
    }

  };

  useEffect(() => {

    setImagePreview(null);


  }, [userHistory]);


  window.addEventListener('beforeunload', () => {
    localStorage.removeItem("storedImagePreviews");
  });


  // const [showPopup, setShowPopup] = useState(false);
  // const [isFirstClick, setIsFirstClick] = useState(false);

  useEffect(() => {
    // Check localStorage to see if the button has been clicked before
    const buttonClicked = localStorage.getItem('buttonClicked');
    if (!buttonClicked) {
      setIsFirstClick(true);
    }
  }, []);

  const handleClick = () => {
    if (isFirstClick) {
      setShowPopup(true);
      localStorage.setItem('buttonClicked', 'true');
      setIsFirstClick(false);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  
  
  const style = {
    display: selectedType === 'type1' || selectedType === 'type2' ? 'block' : 'none'

  };


  return (
    <div className={`input-container   ${!isStyled ? 'start-input-container' : 'input-container-background'}`} style={style}>

      <div className="input-container-wrapper" style={{ ...getText(), ...suggessionBorderColor }}>
        <div className="input-container-inner">
          <textarea
            id="typedText"
            ref={textareaRef}
            className="kps-input-box"
            type="text"
            placeholder={!isRecording && getPlaceholderText()}
            value={input}
            onChange={(e) => {
              handleInputChange(e);

            }}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            required
          />


          {
            input && suggestions.length > 0 && showDropdown && (
              <ul className="autocomplete-dropdown" ref={dropdownRef}>
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="autocomplete-item"
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          <div className="submit-wrapper">
            <div className="submit-voice">
              <span style={getSubmit()}>
                <button
                  type="submit"
                  disabled={disableSendButton}
                  className="submit-button comman-button-text-input sendtool"
                  onClick={() => {
                    setCenterHome(false);
                    setSelectedImage(null);
                  }}

                  onMouseEnter={handleMouseEnterSend}
                  onMouseLeave={handleMouseLeaveSend}
                >
                  <img
                    onClick={() => {
                      handleStoreButtonClick(); handleRetrieveButtonClick();


                    }}

                    className="comman-button-text-input-img"
                    src={msgSend}
                    alt="Chat"
                    disabled={disableSendButton || isSubmitting}
                  />
                  {showTooltip && <div style={{ color: '#000', }}>
                    <div class="">
                      <span class="sendtooltext" style={{ marginLeft: '28px' }}>Submit</span>
                    </div>
                  </div>}

                </button>
              </span>
              {
                isRecording &&
                <div className="listening-wrapper">Listening</div>

              }
              <span style={getVoice()}>

                {isFirstClick ? (
                  <button
                    className="submit-button ripple-btn comman-button-text-input sendtool"
                    type="button"
                    onClick={handleClick}
                    disabled={disableSendButton}
                  >

                    <img
                      className="comman-button-text-input-img chat-logo-voice"
                      src={voiceLogo}
                      alt="Microphone"
                    />


                  </button>) : (

                  <button
                    className="submit-button ripple-btn comman-button-text-input sendtool"
                    type="button"
                    onClick={handleMicButtonClick}
                    disabled={disableMicButton}
                    onMouseEnter={handleMouseEnterMic}
                    onMouseLeave={handleMouseLeaveMic}
                  >
                    {isRecording ? (
                      <img
                        className="comman-button-text-input-img chat-logo-record"
                        src={recordIcon}
                        alt="Record"
                      />
                    ) : (
                      <img
                        className="comman-button-text-input-img chat-logo-voice"
                        src={voiceLogo}
                        alt="Microphone"
                      />
                    )}
                    {showTooltipMic && <div style={{ color: '#000', }}>
                      <div class="">
                        <span class="sendtooltext">Use microphone</span>
                      </div>
                    </div>}
                  </button>)}
              </span>
            </div>
          </div>
        </div>
        {input && <div className="count-wrapper">
          <div className="count-container"
            style={{
              color: textCount === 0 ? '#aaaeaa' : (textCount < 2000 ? 'green' : 'red')
            }}
          >
            {formattedCount}
          </div>
        </div>}
      </div>

    </div>
  );
};

export default ChatInput;
