import React from 'react'
import closeIcon from "../../img/close_icon.png";
import { useShareWindow } from '../../context/ShareWindowContext';
import ChatType from '../ChatType/ChatType';

function PopupTypes() {
    const { setShowTypes, showTypes
    } = useShareWindow();
    return (
        (
            <div className="main-container-login">
                <div className="main-container-inner-login">
                    <div className="login-container">
                        <div className="login-card">
                            <div className="back-button-kps-style">
                                <a className="back-btn" onClick={() => setShowTypes(prev => !prev)}>
                                    <img src={closeIcon} alt="Home" />
                                </a>
                            </div>
                            <h2>Types</h2>
                            <div className="chat-type-wrapper" >
                                <ChatType />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}

export default PopupTypes