import React, { useCallback, useEffect, useRef, useState } from "react";
import Form from "../Form/Form";
import { useParams } from "react-router-dom";
import userlogo from "../../img/Icons/login.png";
import mainlogo from "../../img/logo/edu_advisor_logo.png";
import aspirations_edu_advisor_platform_logo from "../../img/logo/aspirations_edu_advisor_platform_logo.png";
import LoginPage from "../Login/Login";
import "./MainLayout.css";
import "../Start/Start.css";
import Navbar from "../Navbar/Navbar";
import chat_icons from "../../img/chat/chat_icon.svg";
import share_icons from "../../img/chat/share_icon.svg";
import telegram_icon from "../../img/chat/telegram_icon.svg";
import whatsapp_icon from "../../img/chat/whatsapp_icon.svg";
import burger_menu from "../../img/versionthree/burger_menu.svg";
import burger_menu_mobile from "../../img/versionthree/burger_menu_mobile.svg";
import back_icon from "../../img/versionthree/back_icon.svg";
import aspirations_edu_advisor_mobile_idle from "../../videos/chat/aspirations_edu_advisor_mobile_idle.mp4";
import aspirations_edu_advisor_mobile_lip_synced from "../../videos/chat/aspirations_edu_advisor_mobile_lip_synced.mp4";
import aspirations_edu_advisor_idle from "../../videos/chat/aspirations_edu_advisor_idle.mp4";
import aspirations_edu_advisor_lip_synced from "../../videos/chat/aspirations_edu_advisor_lip_synced.mp4";
import asprian_bg from "../../img/asprian_bg.jpg";
import aspirations_edu_advisor_mobile_thumbnail from "../../img/chat/aspirations_edu_advisor_mobile_thumbnail.jpg";
import { useShareWindow } from "../../context/ShareWindowContext.js";
import ShareWindow from "../Sharewindow/ShareWindow.js";
import axios from "axios";
import PopupMic from "../PopupMic/PopupMic.js";
import Cookies from 'js-cookie';
import PopupTypes from "../PopupTypes/PopupTypes.js";
import personalityTest from "../../img/options/Personality-Test.png";
import virtualtour from "../../img/options/virtual_tour_icon.png";
import PersonalityTest from "../PersonalityTest/PersonalityTest.js";

const SET_NICKNAME = "https://aspirations.stageyou.com:5000/get-user-nickname";

function Test(props) {
  const buttonRef = useRef(null);
  const [backgroundColor, setBackgroundColor] = useState("transparent");
  const [backgroundMp, setBackgroundMp] = useState(aspirations_edu_advisor_idle);
  const [centerHome, setCenterHome] = useState(true);
  const [mobileNav, setMobileNav] = useState(false);
  const [nickname, setNickname] = useState("");
  const { session: urlSession } = useParams();
  const { showCookieMessage, setShowCookieMessage,
    showPopup,
    showShareWindow,
    setShowShareWindow,
    chatSession,
    setChatSession,
    setBackgroundText,
    setSelectedType,
    userType,
    selectedType, showDev
    , setShowDiv, setBackgroundImg, minMaxHeight, setDeskNav, backgroundreturnColor, setBackgroundreturnColor, deskNav, isStyled, setIsStyled, setChatStart, audioVideoChange, setShowTypes, showTypes
  } = useShareWindow();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const chatType = localStorage.getItem('chatType');

    if (chatType) {
      setIsStyled(!isStyled);
      setChatStart(true);
      localStorage.removeItem('chatType');
    }

    return () => {
      // Ensure this cleanup function exists even if it does nothing
    };
  }, []);


  const uniqueLink = `http://localhost:3001/share/${chatSession}`;
  // const uniqueLink = `https://.knightingale.ai/knask-video/share/${chatSession}`;

  const [conversationHistory, setConversationHistory] = useState([]);




  // useEffect(() => {
  //   // Fetch user nickname from the backend
  //   fetch(SET_NICKNAME)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setNickname(data.nickname);
  //     })
  //     .catch((error) => console.error("Error fetching user nickname:", error));
  // }, []);

  useEffect(() => {
    buttonRef.current.click();
  }, []);

  const videoRef = useRef(null);
  const handlePlayClick = () => {
    const video = videoRef.current;
    if (video && typeof video.play === "function") {
      video.play().catch((error) => {
        console.error("Autoplay error:", error);
      });
    }
  };


  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };


  const divStyle = {
    backgroundColor: backgroundColor,
  };

  const backgroundOpacity = .5;

  const returnStyle = {
    backgroundColor: `rgba(${parseInt(
      backgroundreturnColor.slice(1, 3),
      16
    )}, ${parseInt(backgroundreturnColor.slice(3, 5), 16)}, ${parseInt(
      backgroundreturnColor.slice(5, 7),
      16
    )}, ${backgroundOpacity})`,
    borderColor: backgroundreturnColor,
  };

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);


  const updateScreenHeight = () => {
    const newHeight = Math.max(648, Math.min(1500, window.innerHeight));
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenHeight);
    updateScreenHeight();

    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);


  let backClicked = false;

  useEffect(() => {
    if (!centerHome) {
      const handlePopState = () => {
        if (!backClicked) {
          backClicked = true;
        } else {
          window.location.reload();
        }
      };

      window.addEventListener("popstate", handlePopState);

      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }
  }, [centerHome]);


  useEffect(() => {
    if (centerHome === false) {
      const handleClick = () => {
      };

      const autoclickElement = document.getElementById("autoclick");

      if (autoclickElement) {
        autoclickElement.addEventListener("click", handleClick);
        autoclickElement.click();
        return () => {
          autoclickElement.removeEventListener("click", handleClick);
        };
      }
    }
  }, [centerHome]);


  useEffect(() => {
    if (urlSession) {
      // If a session ID is present in the URL, use it
      setChatSession(urlSession);

      // Fetch conversation history for the current chat session
      axios
        .get(`https://dashboard.knightingale.ai/ai/conversation-history/${urlSession}`)
        .then((response) => {
          // Update conversation history state
          setConversationHistory(response.data.conversations);
          console.log(response.data.conversations);
        })
        .catch((error) => {
          console.error("Error fetching conversation history:", error);
        });
    } else {
      // If no session ID in the URL, fetch a new session ID when the component mounts
      axios
        .get("https://dashboard.knightingale.ai/ai/generate-session")
        .then((response) => {
          const newChatSession = response.data.chatSession;
          // Store the new session ID in localStorage
          setChatSession(newChatSession);
          localStorage.setItem("chatSession", newChatSession);
        })
        .catch((error) => {
          console.error("Error generating session:", error);
        });
    }
  }, [urlSession]);


  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [zstyle, setZstyle] = useState(false);

  const handleMouseEnterShare = () => {
    setZstyle(true)
    const timeout = setTimeout(() => {
      setShowTooltip(true);
    }, 2000); // 2 seconds
    setHoverTimeout(timeout);
  };

  const handleMouseLeaveShare = () => {
    clearTimeout(hoverTimeout);
    setShowTooltip(false);
    setZstyle(false)
  };


  const newChatClick = () => {
    // Store 'newchat' in localStorage
    localStorage.setItem('chatType', 'newchat');
    // alert("Value 'newchat' has been stored in localStorage.");
  };



  const [objectFitStyle, setObjectFitStyle] = useState('');

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const screenRatio = screenWidth / screenHeight;

      // Check the screen ratio and set the object-fit style accordingly
      if (screenRatio < 1.8) {
        setObjectFitStyle('cover');
      } else {
        setObjectFitStyle('cover');
      }
    };

    // Call handleResize on initial mount and window resize
    handleResize();
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  useEffect(() => {
    const messageShown = Cookies.get('messageShown');
    if (!messageShown) {
      setShowCookieMessage(true);
    }
  }, []);

  const handleOkClick = () => {
    // Set the cookie when the user clicks OK
    Cookies.set('messageShown', 'true', { expires: 7 }); // The cookie will expire in 7 days
    setShowCookieMessage(false);
  };

  useEffect(() => {
    console.log("first" + loaded)
    setDeskNav(false)
  }, [!loaded]);



  const openWhatsAppChat = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://aspirations.stageyou.com:5000/api/whatsapp/chat', {
        // Add any necessary data here
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log(response.data);
      // Handle the response data
      // Redirect or perform other actions based on the response
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const imgRef = useRef(null);

  const refreshImage = useCallback(() => {
    if (imgRef.current) {
      const currentSrc = imgRef.current.src;
      // Append a timestamp to the URL to force the browser to reload the image
      imgRef.current.src = currentSrc.split('?')[0] + '?' + new Date().getTime();
    }
  }, []);

  const getSelectionResult = (selectedType) => {
    switch (selectedType) {
      case "type1":
        return "Uni Guide";
      case "type2":
        return "Personality";
      case "type4":
        return "Virtual Tour";
      default:
        return "Unknown Type";
    }
  };

  return (
    <>
      {!showDev ?
        (
          <div
            className={`main-three-colum-layout-wrapper  ${!isStyled ? 'start-bg-color' : ''} `}
            style={divStyle}
          >

            <div className="main-three-colum-layout-container">

              <div className="desk_home_bg_video_wrapper">
                <div className="desk_home_bg_video_container">
                  <div className="new-background-video-container-chat videoclass">
                    <div
                      style={{
                        width: '100%',
                        height: 'auto',
                        opacity: `${audioVideoChange ? '0' : ''}`,
                        position: 'absolute',
                        transition: 'opacity 1ms',
                      }}
                      className="video_wrapper_dp"
                    >
                      <video
                        className={`new-background-bg-video-container-chat-video desktop_background_video `}
                        style={{
                          objectFit: objectFitStyle,
                          height: '100%',
                          width: '100%',
                        }}
                        playsInline
                        autoPlay
                        loop
                        muted
                        poster={asprian_bg}
                        src={aspirations_edu_advisor_idle}
                      />

                      <video
                        className={`new-background-bg-video-container-chat-video mobile_background_video `}
                        style={{
                          objectFit: 'cover',
                          height: '100%',
                          width: '100%',
                        }}
                        playsInline
                        autoPlay
                        loop
                        muted
                        poster={aspirations_edu_advisor_mobile_thumbnail}
                        src={aspirations_edu_advisor_mobile_idle}
                      />
                    </div>
                    <div
                      style={{
                        opacity: `${audioVideoChange ? '1' : '0'}`,
                        transition: 'opacity 1ms',
                        position: 'absolute',
                        height: '100%',
                        width: '100%',

                      }}

                      className="video_wrapper_dp"

                    >

                      <video
                        className="new-background-bg-video-container-chat-video desktop_background_video"
                        style={{
                          objectFit: objectFitStyle,
                          height: '100%',
                          width: '100%',
                        }
                        }
                        playsInline
                        autoPlay
                        loop
                        muted
                        poster={asprian_bg}
                        src={aspirations_edu_advisor_lip_synced}
                      />

                      <video
                        className="new-background-bg-video-container-chat-video mobile_background_video"
                        style={{
                          objectFit: 'cover',
                          height: '100%',
                          width: '100%',
                        }
                        }
                        playsInline
                        autoPlay
                        loop
                        muted
                        poster={aspirations_edu_advisor_mobile_thumbnail}
                        src={aspirations_edu_advisor_mobile_lip_synced}
                      />
                    </div>


                  </div>
                </div>
              </div>
              <LoginPage isOpen={isPopupOpen} onClose={closePopup}></LoginPage>
              {showTypes && <PopupTypes />}
              <div
                className={`main-three-colum-layout    ${!isStyled ? 'start-main-three-colum-layout' : ''}`}
                style={divStyle}
              >
                <video
                  ref={videoRef}
                  className="new-mobile-background-video"
                  playsInline
                  muted
                  loop
                />

                <button
                  ref={buttonRef}
                  onClick={() => handlePlayClick()}
                  style={{ display: "none" }}
                ></button>
                <div className={`left-column   ${!isStyled ? 'start-left-column' : ''}`}>


                  <div className={`left-column-logo  ${!isStyled ? '' : 'left-column-logo-hide'}`}>
                    <div className="nav_desktop_tbtn">
                      <div className="toggle_menu_wrapper" onClick={() => { setDeskNav(!deskNav) }}>
                        <img className="toggle_menu_desk" src={burger_menu} alt="Image 1" />
                      </div>
                    </div>
                    {/* <a href="https://aspira.aspirationsilab.com/">
                  <img
                    className="logo-kps menu-logo-kps "
                    src={mainlogo}
                    alt="Image 1"
                  />
  
                </a> */}

                    <div className="right_side_nav">
                      <div className="right_side_nav_inner">
                        {selectedType != null && <>
                          {showDev === false ? (<button className="button-selected-type"
                            onClick={() => setSelectedType('type2')}
                            style={{ backgroundColor: selectedType === 'type2' && "#fff", borderColor: selectedType === 'type2' && '#ffc801' }}
                          >
                            <img src={personalityTest} alt="personality" />

                            Personality
                          </button>) : (
                            <button className="button-selected-type"
                              onClick={() => setShowDiv(!showDev)}
                              style={{ backgroundColor: selectedType === 'type2' && "#fff", borderColor: selectedType === 'type2' && '#ffc801' }}
                            >
                              <img src={personalityTest} alt="personality" />

                              Personality
                            </button>)}
                          {userType === 'user' ? (
                            <button className="button-selected-type"
                              onClick={() => setSelectedType('type4')}
                              style={{ backgroundColor: selectedType === 'type4' && "#fff", borderColor: selectedType === 'type4' && '#ffc801' }}

                            >
                              <img src={virtualtour} alt="Virtual Tour" />
                              Virtual Tour
                            </button>
                          ) :
                            (
                              <button onClick={() => setSelectedType('type1')} className="button-selected-type"

                                style={{ backgroundColor: selectedType === 'type1' && "#fff", borderColor: selectedType === 'type1' && '#ffc801' }}

                              >
                                UniGuide
                              </button>
                            )}
                        </>
                        }

                        <button onClick={openPopup} className="button-cmn">
                          <img className="user-kps " src={userlogo} alt="Image 1" style={{ width: '40px' }} />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className={`left-column-vertical-buttons ${deskNav ? '' : 'deskNav'}`}>
                    <div className="vertical-button-wrapper">
                      <div className="vertical-button"
                      >
                        <a href="https://aspira.aspirationsilab.com/">
                          <img
                            className="vertical-button-img"
                            src={chat_icons}
                            alt="Image 1"
                          />
                          <div className="side_nav_text">24/7<span style={{ color: 'transparent' }}>_</span>Chat
                          </div>
                        </a>
                      </div>
                      <div className="vertical-button">

                        <div className="sharebtn-wrapper start-sharebtn-wrapper">
                          <div className="sharebtn-inner">
                            <div className="sharebtn"
                              onMouseEnter={handleMouseEnterShare}
                              onMouseLeave={handleMouseLeaveShare}
                            >

                              <button
                                className="sharebtn-img tooltip"
                                onClick={() => setShowShareWindow(true)}
                              // disabled={isButtonDisabled}

                              >
                                <img
                                  className="vertical-button-img share"
                                  src={share_icons}
                                  alt="Image 1"
                                />
                                <div className="side_nav_text">Share</div>
                              </button>
                              {showShareWindow && (
                                <ShareWindow
                                  generatedLink={uniqueLink}
                                  onClose={() => setShowShareWindow(false)}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                  <div className="video-wrapper">
                    <a href="/" onClick={refreshImage}>
                      <img
                        ref={imgRef}
                        className="logo-kps menu-logo-kps menu-logo-kps-desktop"
                        src={aspirations_edu_advisor_platform_logo}
                        alt="Image 1"
                      />

                    </a>
                    <div className="new-background-video-container-chat videoclass">

                    </div>
                    {/* {showCookieMessage && <div className="cookie_bars" style={{}}>
                  <p>Click to allow cookies and improve your experience!</p>
                  <button onClick={handleOkClick}>Accept All</button>
                </div>
                } */}
                  </div>
                </div>
                {<a href="#" id="autoclick"></a>}

                <div
                  className={`center-column  ${!isStyled ? 'start-central-column' : ''}  ${showCookieMessage ? 'showCookieMessage' : 'hideCookieMessage'}`}
                >

                  <div className="mobile_1v3_nav">
                    <div className="mobile_1v3_nav_wrapper">
                      <div className="mobile_1v3_nav_container">
                        <div className="mobile_1v3_nav_toggle">
                          <div className="toggle_menu_wrapper" onClick={() => { setMobileNav(!mobileNav) }}>
                            <img className="toggle_menu" src={burger_menu_mobile} alt="Image 1" />
                          </div>
                        </div>

                        <div className="mobile_1v3_nav_logo">
                          <a href="/" onClick={refreshImage}>
                            <img
                              ref={imgRef}
                              className="logo-kps menu-logo-kps "
                              src={mainlogo}
                              alt="Image 1"
                            />
                          </a>
                        </div>
                        <div className="right_side_nav_inner">
                          {/* <button onClick={() => setShowTypes(prev => !prev)} className="button-cmn button-selected-type">{getSelectionResult(selectedType)}</button> */}
                          {/* {selectedType != null &&
                            <div style={{ position: 'absolute', top: 60, zIndex: 10000, display: 'flex', gap: 20, width: '100%', justifyContent: 'center' }}>
                              {showDev === false ? (<button className="button-cmn button-selected-type"
                                onClick={() => setSelectedType('type2')}
                                style={{ backgroundColor: selectedType === 'type2' && "#fff", borderColor: selectedType === 'type2' && '#ffc801' }}
                              >
                                <img src={personalityTest} alt="personality" />

                                Personality
                              </button>) : (
                                <button className="button-cmn button-selected-type"
                                onClick={() => setShowDiv(!showDev)}
                                  
                                  style={{ backgroundColor: selectedType === 'type2' && "#fff", borderColor: selectedType === 'type2' && '#ffc801' }}
                                >
                                  <img src={personalityTest} alt="personality" />
                                  Personality
                                </button>
                              )}
                              {userType === 'user' ? (
                                <button className="button-cmn button-selected-type"
                                  onClick={() => setSelectedType('type4')}
                                  style={{ backgroundColor: selectedType === 'type4' && "#fff", borderColor: selectedType === 'type4' && '#ffc801' }}

                                >
                                  <img src={virtualtour} alt="virtualtour" />

                                  Virtual Tour
                                </button>
                              ) :
                                (
                                  <button onClick={() => setSelectedType('type1')} className="button-cmn button-selected-type"

                                    style={{ backgroundColor: selectedType === 'type1' && "#fff", borderColor: selectedType === 'type1' && '#ffc801' }}

                                  >
                                    UniGuide
                                  </button>
                                )}
                            </div>
                          } */}
                        </div>
                        <button onClick={openPopup} className="button-cmn button-login">
                          <img className="user-kps " src={userlogo} alt="Image 1" />
                        </button>
                      </div>
                      {selectedType != null &&
                            <div style={{ position: 'relative',  zIndex: 10000, display: 'flex', gap: 20, width: '100%', justifyContent: 'center' }}>
                              {showDev === false ? (<button className="button-cmn button-selected-type"
                                onClick={() => setSelectedType('type2')}
                                style={{ backgroundColor: selectedType === 'type2' && "#fff", borderColor: selectedType === 'type2' && '#ffc801' }}
                              >
                                <img src={personalityTest} alt="personality" />

                                Personality
                              </button>) : (
                                <button className="button-cmn button-selected-type"
                                onClick={() => setShowDiv(!showDev)}
                                  
                                  style={{ backgroundColor: selectedType === 'type2' && "#fff", borderColor: selectedType === 'type2' && '#ffc801' }}
                                >
                                  <img src={personalityTest} alt="personality" />
                                  Personality
                                </button>
                              )}
                              {userType === 'user' ? (
                                <button className="button-cmn button-selected-type"
                                  onClick={() => setSelectedType('type4')}
                                  style={{ backgroundColor: selectedType === 'type4' && "#fff", borderColor: selectedType === 'type4' && '#ffc801' }}

                                >
                                  <img src={virtualtour} alt="virtualtour" />

                                  Virtual Tour
                                </button>
                              ) :
                                (
                                  <button onClick={() => setSelectedType('type1')} className="button-cmn button-selected-type"

                                    style={{ backgroundColor: selectedType === 'type1' && "#fff", borderColor: selectedType === 'type1' && '#ffc801' }}

                                  >
                                    UniGuide
                                  </button>
                                )}
                            </div>
                          }
                    </div>
                  </div>
                  <div className="mobile_1v3_side_nav_open start_mobile_1v3_side_nav ">
                    <div className={`mobile_1v3_side_nav ${mobileNav ? "mobile_1v3_side_nav_open" : ""
                      }`}>
                      <div className={`mobile_1v3_side_nav_wrapper   ${mobileNav ? 'mob_bg_overlay' : ''}`}>
                        <div className="mobile_1v3_side_nav_container">
                          <div className="side_nav_menu_container">
                            <div className="side_nav_menu_item_wrapper">
                              <div className="side_nav_menu_item_container">
                                <div className=" side_nav_menu_item_back">
                                  <a href="#" onClick={() => { setMobileNav(!mobileNav) }} className="">
                                    <img
                                      className="vertical-button-img"
                                      src={back_icon}
                                      alt="Image 1"
                                    />
                                  </a>
                                </div>
                                <div className="side_nav_menu_item_wrapper">

                                  <div className="side_nav_menu_item">
                                    <a href="https://aspira.aspirationsilab.com/">
                                      <img
                                        className="vertical-button-img"
                                        src={chat_icons}
                                        alt="Image 1"
                                      />

                                      <div className="side_nav_menu_item_text">
                                        24/7 Chat
                                      </div> </a>
                                  </div>
                                  <div className="side_nav_menu_item" onClick={() => setShowShareWindow(true)}>
                                    <div className="sharebtn-wrapper start-sharebtn-wrapper">
                                      <div className="sharebtn-inner">
                                        <div className="sharebtn"
                                          onMouseEnter={handleMouseEnterShare}
                                          onMouseLeave={handleMouseLeaveShare}
                                        >

                                          <button
                                            className="sharebtn-img tooltip"
                                            onClick={() => setShowShareWindow(true)}
                                          // disabled={isButtonDisabled}

                                          >
                                            <img
                                              className="vertical-button-img share"
                                              src={share_icons}
                                              alt="Image 1"
                                            />
                                            {showTooltip && <div class="tooltip">
                                              <span class="tooltiptext">Share</span>
                                            </div>}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="side_nav_menu_item_text">
                                      Share
                                    </div>
                                  </div>

                                  {/* <div className="side_nav_menu_item" onClick={newChatClick}>
                                <a href="https://aspira.aspirationsilab.com/"  >
                                  <img
                                    className="vertical-button-img"
                                    src={chat_icon}
                                    alt="Image 1"
                                  />
  
                                  <div className="side_nav_menu_item_text">
                                    New Chat
                                  </div></a>
                              </div> */}
                                </div>
                              </div>
                              <div className="side_nav_menu_item_bottom">
                                <div className="side_nav_menu_item_bottom_text">
                                  {/* © 2024 Knightingale, Inc. */}
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>




                  {/* <div className={`central-greeting-wrapper-mobile ${!isStyled ? 'start-central-greeting-wrapper' : ''}`}>
             
              </div> */}
                  <div className={` start-central-video-display  ${!isStyled ? 'start-cental-video-wrapper' : ''}`}>
                    <div className="video-wrapper videoclass" id="yourVideoDivId">
                      <div className="new-background-video-container videoclass">
                        <video
                          className="start-video"
                          id="start_video"
                          playsInline
                          autoPlay
                          loop
                          muted
                        />

                      </div>
                    </div>
                  </div>


                  <div style={{}} className="greeting_form_wrapper">
                    <div className="central-greeting-wrapper-desktop">
                      <div
                        className={`central-greeting-wrapper  app  ${!isStyled ? 'start-central-greeting-wrapper' : ''}  ${loaded ? "loaded" : ""} `} style={{ top: (minMaxHeight * -1) < 2 ? `${-70 + (minMaxHeight * -1)}px` : `${-80}px` }}

                      >
                      </div>
                    </div>
                    <Form
                      setBackgroundColor={setBackgroundColor}
                      setBackgroundImg={setBackgroundImg}
                      setBackgroundText={setBackgroundText}
                      setBackgroundMp={setBackgroundMp}
                      setBackgroundreturnColor={setBackgroundreturnColor}
                      backgroundMp={backgroundMp}
                      setCenterHome={setCenterHome}
                    />
                  </div>
                </div>

                <div className="right-column ">
                  <Navbar setCenterHome={setCenterHome} />
                </div>
              </div>
              {showShareWindow && (
                <ShareWindow
                  generatedLink={uniqueLink}
                  onClose={() => setShowShareWindow(false)}
                />
              )}
              {
                showPopup && <PopupMic />
              }
            </div>
            {/* {showCookieMessage &&   <div className="cookie_bars cookie_bars_mobile" style={{ }}>
        <p>Click to allow cookies and improve your experience!</p>
        <button onClick={handleOkClick}>Accept All</button>
        </div>
        } */}

          </div>
        ) :
        (
          <PersonalityTest />
        )
      }
    </>
  );
}

export default Test;
