import React, { useEffect, useState } from 'react'
import aspira_experience_graphic from '../../img/virtualtour/aspira_experience_graphic.png';
import './VirtualTour.css'
const VirtualTour = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  
    const handleFinish = () => {
        if (screenWidth>560) {
            
            window.open("/virtual-experiences", "_blank");
        }else{
            window.open("/virtual-experiences-mobile", "_blank");
        }
    };

    

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <div className='vitualtour-wrapper'>
            <div className='vitualtour-container'>
                <img src={aspira_experience_graphic} alt="aspira_experience_graphic" />
                <div className='virtualour-inner'>
                    <h3 style={{ margin: 0 }}>Aspira Virtual Experience</h3>
                    <h3 style={{ margin: 0, fontWeight: 400 }}>(Launching Soon)</h3>
                    <p style={{ marginTop: 20 }}>A virtual experience is a digital simulation that lets users interact and engage in activities remotely through VR, AR, or online platforms.</p>
                    <button onClick={handleFinish} style={{ color: '#000' }} className="button finish-button">Virtual Tour</button>
                </div>
            </div>
        </div>
    )
}

export default VirtualTour