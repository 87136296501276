import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import backImage from "../../img/arrow.png";
import closeIcon from "../../img/close_icon.png";
import { useShareWindow } from "../../context/ShareWindowContext";

const LoginPage = ({ isOpen, onClose, closePopup }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);
  const navigate = useNavigate();
  const { userType, setUserType
  } = useShareWindow();
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = (event) => {
    event.preventDefault();

    // if (username === "knightingale_admin" && password === "2h+:=O^3(`A%F>)GX8#%lTCkm7J~?N") {
    if (username === "admin" && password === "#*aspi2025") {
      setUserType("admin")
      onClose()
      setUsername()
      setPassword()
      setLoginError(false)
      // navigate("/dashboard");
    } else {
      setLoginError(true);
    }
  };

  const handleClose = () => {

    if (onClose) {
      onClose();
      setLoginError(false)

    }

  };

  return (
    isOpen && (
      <div className="main-container-login">
        <div className="main-container-inner-login">
          <div className="login-container">
            <div className="login-card">
              <div className="back-button-kps-style">
                <a className="back-btn" onClick={handleClose}>
                  <img src={closeIcon} alt="Home" />
                </a>
              </div>
              <h2>User Login</h2>
              <form className="login-form" onSubmit={handleLogin}>
                <div className="kps-form-group">
                  <label className="full-with-label" htmlFor="username">
                    Username:
                  </label>
                  <input
                    className="kps-login-input"
                    type="text"
                    id="username"
                    value={username}
                    onChange={handleUsernameChange}
                  />
                </div>
                <div className="kps-form-group kps-form-group-password">
                  <label className="full-with-label" htmlFor="password">
                    Password:
                  </label>
                  <input
                    className="kps-login-input"
                    type="password"
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </div>
                <div className="">
                  {loginError && (
                    <p className="error-message-login">
                      Username or password incorrect
                    </p>
                  )}
                </div>
                <div className="full-with-btn-container">
                  <button className="login-btn filled-btn" type="submit">
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default LoginPage;
