import { useEffect } from 'react';
import './ChatType.css'
import { useNavigate } from 'react-router-dom';
import { useShareWindow } from "../../context/ShareWindowContext.js";
import build_profile from "../../img/chat/build_profile.svg";
import chat_with_aspira from "../../img/chat/chat_with_aspira.svg";
import optiontest from "../../img/options/symptom-checker.png";
import askmeanything from "../../img/options/Ask-Me-Anything.png";
import aspirasuniguide from "../../img/options/Aspiras-UniGuide.png";
import bemybuddy from "../../img/options/Be-My-Buddy.png";
import careerpredict from "../../img/options/Career-Predictor.png";
import personalityTest from "../../img/options/Personality-Test.png";
import virtualtour from "../../img/options/virtual_tour_icon.png";
import profilebuilder from "../../img/options/Profile-Builder.png";
import smartstudent from "../../img/options/Smart-Student.png";
import asphome from "../../img/options/Aspirations-Home.png";


const ChatType = () => {
  const navigate = useNavigate();
  const { userType, setSelectedType, showTypes, setShowTypes, showDev, setShowDiv } = useShareWindow();



  return (
    <div className="chat-type-container">
      <div className="chat-type-inner">
        {!showTypes && <div className="chat-type-items" style={{ flexDirection: 'column' }}>
          {/* <div className="chat-type-item-wrapper">
            <div className="chat-type-item" onClick={() =>   setSelectedType('type1')}>
              <div className='chat-type-item-img'>
                <img src={chat_with_aspira} alt="" />
              </div>
              <p>Chat with Aspira</p>
            </div>
          </div>
          <div className="chat-type-item-wrapper">
            <div className="chat-type-item" onClick={() => window.location.href = '/estimation'}
            >
              <div className='chat-type-item-img'>
                <img src={build_profile} alt="" />
              </div>
              <p>Get Quatetion</p>
            </div>
          </div> */}
          <h3>Hi, I’m Aspira!</h3>
          <p>I’m here to guide you on your journey of self-discovery and exploration. Let’s get started!</p>
        </div>}
        <div className="option-wrapper">
          <div className="option-container">
            <div className="options options1">
              <button className="options-item2" onClick={() => {
                setShowDiv(!showDev)
              }}>
                <div className='options-item2-img'>
                  <img src={personalityTest} alt="optiontest" />
                </div>
                <div className="option-text">
                  <p>Personality</p>
                </div>
              </button>
              {userType === 'user' ? (<button className="options-item2" onClick={() => {
                setSelectedType('type4');
                setShowTypes(false);
              }}>
                <img src={virtualtour} alt="optiontest" />
                <div className="option-text">
                  <p>Virtual Tour</p>
                </div>
              </button>) : (
                <button className="options-item2" onClick={() => {
                  setSelectedType('type1');
                  setShowTypes(false);
                }} >
                  <img src={aspirasuniguide} alt="optiontest" />
                  <div className="option-text">
                    <p>Uni guide</p>
                  </div>
                </button>
              )}
              {/* <button className="options-item2" onClick={() => { 
  setSelectedType('type4'); 
  setShowTypes(false); 
}}>
                <img src={personalityTest} alt="optiontest" />
                <div className="option-text">
                  <p>Virtual Tour</p>
                </div>
              </button>
              <button className="options-item2" onClick={() => { 
  setSelectedType('type2'); 
  setShowTypes(false); 
}}>
                <img src={personalityTest} alt="optiontest" />
                <div className="option-text">
                  <p>Personality</p>
                </div>
              </button> */}
            </div>
            <div className="options options1">

              {/* <button className="options-item" onClick={() =>   setSelectedType('type1')} style={{backgroundColor: '#ffffff91', pointerEvents: 'none'}} disabled cursor="not-allowed">
                      <img src={askmeanything} alt="optiontest" />
                      <div className="option-text">
                        <p>Ask me <br/>anything</p>
                      </div>
                    </button> */}



              {/* <button className="options-item"   style={{backgroundColor: '#ffffff91', pointerEvents: 'none'}}>
                      <img src={profilebuilder} alt="optiontest" onClick={() => window.location.href = '/#'}/>
                      <div className="option-text">
                        <p>Profile<br/> Builder</p>
                      </div>
                    </button> */}
              {/* <button className="options-item"  style={{backgroundColor: '#ffffff91', pointerEvents: 'none'}}>
                      <img src={careerpredict} alt="optiontest" onClick={() => window.location.href = '/#'} />
                      <div className="option-text">
                        <p>Career <br/>Predictor</p>
                      </div>
                    </button> */}

            </div>
            {/* <div className="options options2" >
                <button className="options-item" onClick={() => window.location.href = '/personality-test'}>
                      <img src={personalityTest} alt="optiontest" />
                      <div className="option-text">
                        <p>Personality <br/>Test</p>
                      </div>
                    </button>
                    <button className="options-item" onClick={() => window.location.href = '/assesment'}  style={{backgroundColor: '#ffffff91', pointerEvents: 'none'}}>
                      <img src={smartstudent} alt="optiontest"  />
                      <div className="option-text">
                        <p>Smart<br/> Student</p>
                      </div>
                    </button>
                    <button className="options-item"  style={{backgroundColor: '#ffffff91', pointerEvents: 'none'}}>
                      <img src={bemybuddy} alt="optiontest" />
                      <div className="option-text">
                        <p>Be My <br/>Buddy</p>
                      </div>
                    </button>
                    <button className="options-item" onClick={() => window.location.href = 'https://aspirations.edu.lk/'}  style={{backgroundColor: '#ffffff91', pointerEvents: 'none'}}>
                      <img src={asphome} alt="optiontest" />
                      <div className="option-text">
                        <p>Aspirations <br/>Home</p>
                      </div>
                    </button>
                </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatType